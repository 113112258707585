















































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import Testimonials from '@/components/Testimonials.vue'; // @ is an alias to /src

interface Testimonial {
  type: string;
  title: string;
  text: string;
  date: string;
  name: string;
}

@Component({
  components: {
    Testimonials,
  },
})
export default class Home extends Vue {
  private mailToLink = 'mailto:alvarez.bookkeeping@gmail.com';
  private callToLink = 'tel://1-619-421-7051';
  private testimonials: Testimonial[] = [
    {
      type: 'Bookkeeping',
      title: 'AMAZING!!',
      text: 'Lorem ipsum proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem auci elit conse quat ipsutissem.',
      date: '03.08.2017',
      name: 'by EVELYN GRANT',
    },
    {
      type: 'Payroll',
      title: 'AMAZING!!',
      text: 'Lorem ipsum proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem auci elit conse quat ipsutissem.',
      date: '03.08.2017',
      name: 'by EVELYN GRANT',
    },
    {
      type: 'Payroll',
      title: 'NEVER HAVE TO WORRY',
      text: 'Lorem ipsum proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem auci elit conse quat ipsutissem.',
      date: '03.08.2017',
      name: 'by EVELYN GRANT',
    },
  ];

  private mapsLink = 'https://www.google.com/maps/place/247+E+St,+Chula+Vista,+CA+91910/@32.6455607,-117.0813954,17z/data=!3m1!4b1!4m5!3m4!1s0x80d94e00a8854f65:0xb6df2b21bbbec3df!8m2!3d32.6455607!4d-117.0792014';

  mounted() {
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    console.log(width);
  }
}
